import request from '@/utils/request'

// 查询虚拟车辆表列表
export function listVehicleVirtual (query) {
  return request({
    url: '/iot/vehicleVirtual/list',
    method: 'get',
    params: query
  })
}

// 查询虚拟车辆表详细
export function getVehicleVirtual (id) {
  return request({
    url: '/iot/vehicleVirtual/' + id,
    method: 'get'
  })
}

// 新增虚拟车辆表
export function addVehicleVirtual (data) {
  return request({
    url: '/iot/vehicleVirtual',
    method: 'post',
    data: data
  })
}

// 修改虚拟车辆表
export function updateVehicleVirtual (data) {
  return request({
    url: '/iot/vehicleVirtual',
    method: 'put',
    data: data
  })
}

// 删除虚拟车辆表
export function delVehicleVirtual (id) {
  return request({
    url: '/iot/vehicleVirtual/' + id,
    method: 'delete'
  })
}

// 导出虚拟车辆表
export function exportVehicleVirtual (query) {
  return request({
    url: '/iot/vehicleVirtual/export',
    method: 'get',
    params: query
  })
}
