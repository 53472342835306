<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="虚拟车车辆编号" prop="vid" >
        <a-input v-model="form.vid" placeholder="请输入虚拟车车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="实体车车辆编号" prop="vehicleId" >
        <a-input v-model="form.vehicleId" placeholder="请输入实体车车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="经度" prop="lng" >
        <a-input v-model="form.lng" placeholder="请输入经度" />
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="lat" >
        <a-input v-model="form.lat" placeholder="请输入纬度" />
      </a-form-model-item>
      <a-form-model-item label="城市代码" prop="city" >
        <a-input v-model="form.city" placeholder="请输入城市代码" />
      </a-form-model-item>
      <a-form-model-item label="城市名称" prop="cityName" >
        <a-input v-model="form.cityName" placeholder="请输入城市名称" />
      </a-form-model-item>
      <a-form-model-item label="车辆状态" prop="enabled" >
        <a-radio-group v-model="form.enabled" button-style="solid">
          <a-radio-button v-for="(d, index) in vehicleStatusOptions" :key="index" :value="Number(d.dictValue)">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleVirtual, addVehicleVirtual, updateVehicleVirtual } from '@/api/iot/vehicleVirtual'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      vehicleStatusOptions: [],
      // 表单参数
      form: {
        id: null,
        vid: null,
        vehicleId: null,
        lng: null,
        lat: null,
        city: null,
        cityName: null,
        enabled: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vid: [
          { required: true, message: '虚拟车车辆编号不能为空', trigger: 'blur' }
        ],
        vehicleId: [
          { required: true, message: '实体车车辆编号不能为空', trigger: 'blur' }
        ],
        lng: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        lat: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '城市代码不能为空', trigger: 'blur' }
        ],
        cityName: [
          { required: true, message: '城市名称不能为空', trigger: 'blur' }
        ],
        enabled: [
          { required: true, message: '车辆状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getDicts('iot_vehicle_status').then((response) => {
      this.vehicleStatusOptions = response.data
    })
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        vid: null,
        vehicleId: null,
        lng: null,
        lat: null,
        city: null,
        cityName: null,
        enabled: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleVirtual(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleVirtual(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleVirtual(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
