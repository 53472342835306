var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "虚拟车车辆编号", prop: "vid" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入虚拟车车辆编号" },
                model: {
                  value: _vm.form.vid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vid", $$v)
                  },
                  expression: "form.vid",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "实体车车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入实体车车辆编号" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "经度", prop: "lng" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入经度" },
                model: {
                  value: _vm.form.lng,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lng", $$v)
                  },
                  expression: "form.lng",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "纬度", prop: "lat" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入纬度" },
                model: {
                  value: _vm.form.lat,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lat", $$v)
                  },
                  expression: "form.lat",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "城市代码", prop: "city" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入城市代码" },
                model: {
                  value: _vm.form.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "城市名称", prop: "cityName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入城市名称" },
                model: {
                  value: _vm.form.cityName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cityName", $$v)
                  },
                  expression: "form.cityName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆状态", prop: "enabled" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.enabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "enabled", $$v)
                    },
                    expression: "form.enabled",
                  },
                },
                _vm._l(_vm.vehicleStatusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }